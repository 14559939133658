import request from '@/utils/request';
import URL from '@/shared/constant/url';
import { getUrl, parseApiUrl } from '../utils';
import { SuccessResponse } from '@/types/response';
import { apiProxy } from '../proxy/proxy';
import SERVER_TYPE from '@/shared/servertype';

export const getApps: (payload: any) => Promise<SuccessResponse<any>> = (payload: any) =>
  request.get(getUrl(URL.app.GET_APPS), { params: payload });
export const getAppsMain: (payload: any) => Promise<SuccessResponse<any>> = (payload: any) =>
  request.get(getUrl(URL.app.GET_APPS_MAIN), { params: payload });
export const getAppDetailById: (id: string, query?: any) => Promise<SuccessResponse<any>> = (id: string, query?: any) =>
  request.get(getUrl(URL.app.GET_APP_DETAIL, id), {
    params: query,
  });
export const createApp: (payload: any) => Promise<SuccessResponse<any>> = (payload: any) =>
  request.post(getUrl(URL.app.CREATE_APP), payload);
export const createManApp: (payload: any) => Promise<SuccessResponse<any>> = (payload: any) =>
  request.post(getUrl(URL.app.POST_APPS_MAIN), payload);
export const updateAppById: (id: number, payload: any) => Promise<SuccessResponse<any>> = (id: number, payload: any) =>
  request.post(getUrl(URL.app.UPDATE_APP, `${id}`), payload);
export const updateAppsById: (id: string, payload: any) => Promise<SuccessResponse<any>> = (id: string, payload: any) =>
  request.post(getUrl(URL.app.UPDATE_APPS_MAIN, id), payload);
export const deleteAppById: (id: number) => Promise<SuccessResponse<any>> = (id: number) =>
  request.post(getUrl(URL.app.DELETE_APP, `${id}`));
export const deleteAppsById: (id: string) => Promise<SuccessResponse<any>> = (id: string) =>
  request.post(getUrl(URL.app.DELETE_APPS_DELETE, id));
export const validateName: (payload: any) => Promise<SuccessResponse<any>> = (payload: any) =>
  request.post(getUrl(URL.app.DELETE_APP), payload);

export const initApplyApp: (id: string, initTimes: number, source: number) => Promise<SuccessResponse<any>> = (
  id: string,
  initTimes: number,
  source: number,
) => {
  const url = initTimes > 0 && source !== 2 ? URL.app.POST_APPLY_APP : URL.app.POST_INIT_APP;
  return request.post(getUrl(url), {
    id,
  });
};

export const startApp: (data: object) => Promise<SuccessResponse<any>> = (data: object) =>
  request.post(getUrl(URL.app.POST_START_APP), { ...data });

// 创建应用编排app
export const engineCreateApp: (data: object) => Promise<SuccessResponse<any>> = (data: object) =>
  request.post(getUrl(URL.app.ENGINE_CREATE_APP), { ...data });
// 推送关联服务到应用编排
export const engineServicesApp: (appId: string, data: object) => Promise<SuccessResponse<any>> = (
  appId: string,
  data: object,
) => request.post(getUrl(parseApiUrl(URL.app.ENGINE_SERVICES_APP, { appId })), { ...data });
// 推送关联服务到应用编排
export const enginePackageApp1: (appId: string, data: object) => Promise<SuccessResponse<any>> = (
  appId: string,
  data: object,
) => request.post(getUrl(parseApiUrl(URL.app.ENGINE_PACKAGE_APP, { appId })), { ...data });
// 拉取代码包
export const enginePackageApp: (appId: string, payload: any) => Promise<SuccessResponse<any>> = (
  appId: string,
  payload: any,
) =>
  request.get(getUrl(parseApiUrl(URL.app.ENGINE_PACKAGE_APP, { appId })), {
    params: payload,
  });

export const stopApp: (id: string) => Promise<SuccessResponse<any>> = (id: string) =>
  request.post(getUrl(URL.app.POST_STOP_APP), { id });

export const syncAppStatusByTSF: (ids: number[]) => Promise<SuccessResponse<any>> = (ids: number[]) =>
  request.post(getUrl(URL.app.UPDATE_APP_STATUS), { ids });

// 获取服务依赖
export const getAppDepend: (payload: any) => Promise<SuccessResponse<any>> = (payload: any) =>
  request.get(getUrl(URL.app.GET_APP_DEPEND), {
    params: payload,
  });
// 获取应用最新版本信息
export const getLatestAppInfo = (appId: number, payload?: any) =>
  // eslint-disable-next-line
  // @ts-ignore
  apiProxy(SERVER_TYPE.SERVICE_REPOSITORY, parseApiUrl(URL.app.GET_LATEST_APP_INFO, { appId }), {
    method: 'get',
    data: payload,
  });

// 发版
export const publishAppVersion = (payload: any) =>
  apiProxy(SERVER_TYPE.SERVICE_REPOSITORY, URL.app.PUBLISH_APP_VERSION, {
    method: 'post',
    data: payload,
  });

// 查询可发布的应用版本
export const getAppsForApply = () =>
  apiProxy(SERVER_TYPE.SERVICE_REPOSITORY, URL.app.GET_APP_FOR_APPLY, {
    method: 'get',
  });

// 获取服务依赖
export const getAppCenterAppList: (payload: any) => Promise<SuccessResponse<any>> = (payload: any) =>
  request.get(getUrl(URL.app.GET_APP_CENTER_APP_LIST), {
    params: payload,
  });
