
import { computed, defineComponent, ref } from 'vue';
import { getSnapshotInfo } from '@/api/repository';
import { parseDescriptionHtml } from '../util';
export default defineComponent({
  name: 'VersionInfoDialog',
  components: {},
  setup() {
    const visible = ref(false);
    const serviceDependRef = ref(null as any);
    const activeTab = ref('base');
    const snapshot = ref(null as any);
    const loading = ref(true);
    const serviceInfo = ref(null as any);
    const fetchData = async (snapshotNo: string) => {
      loading.value = true;
      try {
        const { data } = await getSnapshotInfo({
          snapshotNo,
          skuType: serviceInfo.value.isApp ? 1 : 0,
        });
        snapshot.value = data;
      } catch (e) {
        console.log(e);
      } finally {
        loading.value = false;
      }
    };
    const handleClose = () => {
      visible.value = false;
      activeTab.value = 'base';
    };
    const handleOpen = (snapshotNo: string, info: any) => {
      visible.value = true;
      serviceInfo.value = info;
      console.log(info, 12345432);
      fetchData(snapshotNo);
    };
    const formatSql = (sql: string) => (sql || '').replace(/\n/gm, '<br/>').replace(/\t/gm, '&nbsp;&nbsp;&nbsp;&nbsp;');
    const showSqlTab = computed(() => {
      const info = serviceInfo.value;
      if (!info || (info.isService && info.appearance === 2)) {
        return false;
      }
      return true;
    });
    return {
      visible,
      handleOpen,
      handleClose,
      serviceDependRef,
      activeTab,
      snapshot,
      loading,
      formatSql,
      parseDescriptionHtml,
      showSqlTab,
      serviceInfo,
    };
  },
});
