import request from '@/utils/request';
import URL from '@/shared/constant/url';
import { getUrl } from '../utils';
import { SuccessResponse } from '@/types/response';
import { apiProxy } from '@/api/proxy/proxy';
import SERVER_TYPES from '@/shared/servertype';
// import service from "@/shared/constant/url/service";
const { repository } = URL;

// 获取仓库列表
export const getRepositoryList: (payload: any) => Promise<SuccessResponse<any>> = (payload: any) =>
  request.get(getUrl(repository.GET_REPOSITORY_LIST_URL), { params: payload });

// 拉取到租户仓库
export const pullRepository: (payload: any) => Promise<SuccessResponse<any>> = (payload: any) =>
  request.post(getUrl(repository.POST_PULL_REPOSITORY), payload);
// 共享
export const shareRepository: (payload: any) => Promise<SuccessResponse<any>> = (payload: any) =>
  request.post(getUrl(repository.POST_SHARE_REPOSITORY), payload);
// 下发
export const distributeRepository: (payload: any) => Promise<SuccessResponse<any>> = (payload: any) =>
  request.post(getUrl(repository.POST_DISTRIBUTE_REPOSITORY), payload);

// 获取服务依赖
export const getServiceDepend: (payload: any) => Promise<SuccessResponse<any>> = (payload: any) =>
  request.get(getUrl(repository.GET_SERVICE_DEPEND), {
    params: payload,
  });

// 获取服务详情
export const getRepositoryDetail: (id: string, type?: number) => Promise<SuccessResponse<any>> = (
  id: string,
  type?: number,
) =>
  request.get(getUrl(repository.GET_REPOSITORY_DETAIL_URL, String(id)), {
    params: {
      type,
    },
  });

// 获取服务历史
export const getRepositoryHistoryForApp: (payload: any) => Promise<SuccessResponse<any>> = (payload: any) =>
  request.get(getUrl(repository.GET_REPOSITORY_HISTORY_APP), {
    params: payload,
  });

export const getRepositoryHistory = (payload: any) =>
  apiProxy(SERVER_TYPES.SERVICE_REPOSITORY, repository.GET_REPOSITORY_HISTORY, {
    method: 'GET',
    params: payload,
  });

// 获取快照详情
export const getSnapshotInfo: (payload: any) => Promise<SuccessResponse<any>> = (payload: any) =>
  request.get(getUrl(repository.GET_REPOSITORY_SNAPSHOT), {
    params: payload,
  });

// 查询下发服务时可用的版本列表
export const getServiceVersions: (payload: object) => Promise<SuccessResponse<any>> = (payload: object) =>
  request.get(getUrl(repository.GET_REPOSITORY_SERVICE_VERSIONS), {
    params: payload,
  });

export const saveAppCenterInnerApp = (payload: any) =>
  apiProxy(SERVER_TYPES.SERVICE_REPOSITORY, repository.POST_APP_CENTER_SAVE_INNER_APP, {
    method: 'POST',
    data: payload,
  });

export const saveAppCenterOuterApp = (payload: any) =>
  apiProxy(SERVER_TYPES.SERVICE_REPOSITORY, repository.POST_APP_CENTER_SAVE_OUTER_APP, {
    method: 'POST',
    data: payload,
  });

export const delAppCenterApp = (id: string) =>
  apiProxy(SERVER_TYPES.SERVICE_REPOSITORY, getUrl(repository.DELETE_APP_CENTER_APP, id), {
    method: 'POST',
    data: {},
  });
export const getInnerApps = () =>
  apiProxy(SERVER_TYPES.SERVICE_REPOSITORY, getUrl(repository.GET_APP_CENTER_INNER_LIST), {
    method: 'GET',
    data: {},
  });
