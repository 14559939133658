import axios from '@/utils/request';
import URL from '@/shared/constant/url';
import { getUrl } from '../utils';
import { SuccessResponse } from '@/types/response';
import { apiProxy } from '../proxy/proxy';
import SERVER_TYPE from '@/shared/servertype';

const { deploy } = URL;
export const getDeployList = (payload: any): Promise<SuccessResponse<any>> =>
  axios.get(getUrl(deploy.GET_DEPLOY_LIST), { params: payload });
export const deleteApply = (id: string): Promise<SuccessResponse<any>> => axios.post(getUrl(deploy.DELETE_APPLY, id));
export const addApply = (payload: any): Promise<SuccessResponse<any>> => axios.post(getUrl(deploy.ADD_APPLY), payload);
export const updateApply = (id: number, payload: any): Promise<SuccessResponse<any>> =>
  axios.post(getUrl(deploy.UPDATE_APPLY, id.toString()), payload);
export const getServiceList: (payload?: any) => Promise<SuccessResponse<any>> = (payload?: any) =>
  axios.get(getUrl(deploy.GET_SERVICE_LIST), { params: payload });
export const findPublisherByName = (payload?: any): Promise<SuccessResponse<any>> =>
  axios.get(getUrl(deploy.QUERY_IN_TENT), { params: payload });
export const getSnapshotNo: (id: number) => Promise<SuccessResponse<any>> = (id: number) =>
  axios.get(getUrl(deploy.GET_SNAPSHOT_NO, id.toString()));

export const getServicesForDeploy = () =>
  apiProxy(SERVER_TYPE.SERVICE_REPOSITORY, deploy.GET_SERVICES_FOR_DEPLOY, {
    method: 'get',
  });
export const getAppsForDeploy = () =>
  apiProxy(SERVER_TYPE.SERVICE_REPOSITORY, deploy.GET_APPS_FOR_DEPLOY, {
    method: 'get',
  });
