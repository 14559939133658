
import { defineComponent, reactive, toRefs, ref, onBeforeMount, onMounted } from 'vue';
import ServiceInfo from './ServiceInfo.vue';
import { AUDIT_RESULTS, getModuleType, getReviewResult, STATUS } from '@/views/deploy/index';
import ServiceName from '@/views/service-management/components/ServiceName.vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import dateFormat from '@/utils/date-format';
import { deleteApply, getDeployList, findPublisherByName, getSnapshotNo } from '@/api/deploy/deploy-apply';
import PackagedPagination from '@/components/pagination/Index.vue';
import ListWrap from '@/components/list-wrap/Index.vue';
import VersionInfoDialog from '@/views/service-repository/detail/Version-Info-Dialog.vue';
import { getShowBool } from '@/utils/permission-show-module';
import { userInfo } from '@/layout/messageCenter/user-info';
import { useSaList } from '@/shared/hooks/list';

interface TableState {
  createDialogVisible: boolean;
  publisherFilters: Array<object>;
  reviewerFilters: Array<object>;
  auditResultsFilters: Array<object>;
}

interface ReleaseState {
  disabled: boolean;
  isEdit: boolean;
  id: string;
  serviceList: Array<object>;
  versionOptions: Array<object>;
  services: Array<object>;
  types: Array<object>;
  serviceInfo: {
    name: string;
    serviceVersion: string;
    type: number;
    moduleId: number;
    publisher: number;
    publisherName: string;
    publishContent: string;
  };
}

export default defineComponent({
  components: { ListWrap, PackagedPagination, ServiceInfo, VersionInfoDialog, ServiceName },
  setup() {
    const tableState: TableState = reactive({
      statusFilters: [],
      auditResultsFilters: [],
      publisherFilters: [],
      reviewerFilters: [],
      createDialogVisible: false,
    });
    const extendQuery = reactive({
      status: null,
      auditResults: null,
      sortField: 'applyTime',
      sortType: 'descending',
    });

    const releaseForm: ReleaseState = reactive({
      disabled: false,
      isEdit: false,
      id: '',
      serviceInfo: {
        type: 1,
        moduleId: 0,
        name: '',
        serviceVersion: '',
        publisher: userInfo.value.userId,
        publisherName: `${userInfo.value.displayName}_${userInfo.value.userName}`,
        publishContent: '',
      },
      serviceList: [],
      versionOptions: [],
      services: [],
      types: [
        { id: 1, name: '服务' },
        { id: 2, name: '应用' },
      ],
    });

    const versionInfoDialogRef = ref(null as any);

    const { loading, query, list, total, fetchList, handleSearch, handlePageChange, handlePageSizeChange } = useSaList(
      async (query: any) => {
        try {
          loading.value = true;
          const { data } = await getDeployList({
            ...query,
            ...extendQuery,
          });
          const { count, rows = [] } = data;
          total.value = count;
          list.value = rows.map((item: any, index: number) => ({
            ...item,
            moduleType: getModuleType(item.type),
            reviewResult: getReviewResult(item.status),
            index: index + 1,
          }));
          tableState.auditResultsFilters = Object.entries(STATUS).map((item) => ({
            id: item[0],
            name: item[1] ? item[1] : '未审核',
          }));
        } catch (e) {}
        loading.value = false;
      },
    );

    fetchList();

    const blackHoverVisible = ref(false);
    const publisherTitleVisiable = ref(false);
    const isShowPopover = ref(true);
    function publisherTitleClick() {
      publisherTitleVisiable.value = true;
      blackHoverVisible.value = true;
    }

    async function publisherChange() {
      publisherTitleVisiable.value = false;
      isShowPopover.value = false;
      blackHoverVisible.value = false;
      await fetchList();
      isShowPopover.value = true;
    }

    const reviewResultsTitleVisiable = ref(false);
    function reviewResultsTitleClick() {
      reviewResultsTitleVisiable.value = true;
      blackHoverVisible.value = true;
    }

    async function auditResultsChange() {
      reviewResultsTitleVisiable.value = false;
      blackHoverVisible.value = false;
      await fetchList();
    }

    const reviewerTitleVisiable = ref(false);
    function reviewerTitleClick() {
      reviewerTitleVisiable.value = true;
      blackHoverVisible.value = true;
    }

    async function reviewerChange() {
      reviewerTitleVisiable.value = false;
      isShowPopover.value = false;
      blackHoverVisible.value = false;
      await fetchList();
      isShowPopover.value = true;
    }

    function blackHoverclick() {
      reviewResultsTitleVisiable.value = false;
      reviewerTitleVisiable.value = false;
      publisherTitleVisiable.value = false;
      blackHoverVisible.value = false;
    }

    function getNameByCode(code: number, type: string): string {
      let name = '';
      switch (type) {
        case 'status':
          name = STATUS[code];
          break;
        case 'auditResults':
          name = AUDIT_RESULTS[code];
          break;
        default:
          break;
      }
      return name;
    }

    function initReleaseForm() {
      releaseForm.disabled = false;
      releaseForm.isEdit = false;
      releaseForm.id = '';
      releaseForm.serviceInfo = {
        type: 1,
        moduleId: 0,
        name: '',
        serviceVersion: '',
        publisher: userInfo.value.userId,
        publisherName: `${userInfo.value.displayName}_${userInfo.value.userName}`,
        publishContent: '',
      };
    }

    const removeApply = async (rowData: any) => {
      ElMessageBox.confirm('是否确定删除该申请？', '提示', {
        confirmButtonText: '确定删除',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const { code } = await deleteApply(rowData.id);
        if (code === 0) {
          ElMessage({
            type: 'success',
            message: '申请删除成功',
          });
          fetchList();
        } else {
          ElMessage({
            type: 'error',
            message: '申请删除失败',
          });
        }
      });
    };

    const openCreateDialog = () => {
      tableState.createDialogVisible = !tableState.createDialogVisible;
    };

    const closeReleaseForm = () => {
      initReleaseForm();
      openCreateDialog();
    };

    function getRowOptionStatus(row: any) {
      // return userInfo.value.userId !== row.publisher || row.status !== 0;
      return row.status !== 0;
    }

    const updateReleaseInfo = (rowData: any) => {
      releaseForm.isEdit = true;
      releaseForm.disabled = true;
      releaseForm.id = rowData.id;
      releaseForm.serviceInfo = {
        ...releaseForm.serviceInfo,
        ...rowData,
      };
      tableState.createDialogVisible = !tableState.createDialogVisible;
    };

    const handleShowVersionInfo = async (row: any) => {
      const { data } = await getSnapshotNo(row?.id);
      versionInfoDialogRef.value.handleOpen(data);
    };

    onBeforeMount(() => {
      blackHoverclick();
    });

    onMounted(async () => {
      const { data = [] } = await findPublisherByName();
      const users = data?.users;
      const publishers = data?.publishers;
      const reviewers = data?.reviewers;
      const userFilters = users.map((item: any) => ({
        id: item.id,
        name: item.displayName,
      }));
      tableState.publisherFilters = userFilters.filter((item: any) => publishers.includes(item.id));
      tableState.reviewerFilters = userFilters.filter((item: any) => reviewers.includes(item.id));
    });

    return {
      ...toRefs(tableState),
      list,
      loading,
      total,
      extendQuery,
      query,
      handleSearch,
      handlePageChange,
      handlePageSizeChange,
      fetchList,
      dateFormat,
      getShowBool,
      openCreateDialog,
      releaseForm,
      closeReleaseForm,
      removeApply,
      updateReleaseInfo,
      getNameByCode,
      getRowOptionStatus,
      blackHoverVisible,
      publisherTitleVisiable,
      publisherTitleClick,
      publisherChange,
      reviewResultsTitleVisiable,
      reviewResultsTitleClick,
      auditResultsChange,
      reviewerTitleVisiable,
      reviewerTitleClick,
      reviewerChange,
      blackHoverclick,
      isShowPopover,
      versionInfoDialogRef,
      handleShowVersionInfo,
    };
  },
});
