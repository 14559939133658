
import { defineComponent, ref, Ref, computed, SetupContext, PropType, reactive, watch, onMounted } from 'vue';
import { ElMessage } from 'element-plus';
import { addApply, updateApply, getServiceList } from '@/api/deploy/deploy-apply';
import ServiceName from '@/views/service-management/components/ServiceName.vue';
import { getAppsForApply } from '@/api/app/index';
interface ReleaseState {
  disabled: boolean;
  isEdit: boolean;
  id: string;
  serviceList: Array<object>;
  versionOptions: Array<object>;
  services: Array<object>;
  types: Array<object>;
  serviceInfo: {
    type: number;
    moduleId: number;
    name: string;
    serviceVersion: string;
    publisher: number;
    publisherName: string;
    publishContent: string;
  };
}
export default defineComponent({
  name: 'AddService',
  props: {
    visable: {
      type: Boolean,
      default: false,
    },
    releaseForms: {
      type: Object as PropType<ReleaseState>,
      default: () => ({}),
    },
  },
  components: {
    ServiceName,
  },
  setup(props: { visable: boolean; releaseForms: ReleaseState }, ctx: SetupContext) {
    const btnLoading = ref(false);
    const labelWidth = ref('80px');
    const isVisable: any = computed(() => props.visable);
    const releaseData: Ref<ReleaseState> = computed(() => props.releaseForms);
    const isEditable: Ref<boolean> = computed(() => releaseData.value.isEdit);
    const releaseFormRef: any = ref(null);
    const releaseRules = {
      type: [{ required: true, message: '请选择发布类型', trigger: 'change' }],
      name: [{ required: true, message: '请输入服务名称', trigger: 'change' }],
      serviceVersion: [{ required: true, message: '请选择发布版本', trigger: 'change' }],
      publisher: [{ type: 'number', message: '请输入数值', trigger: 'change' }],
      publishContent: [
        { required: true, message: '请输入发布说明', trigger: 'blur' },
        {
          min: 1,
          max: 2048,
          message: '长度在 1 到 2048 个字符',
          trigger: 'blur',
        },
      ],
    };

    const form = reactive({
      type: 1,
      publisherName: '',
      publisher: 0,
      name: '',
      serviceVersion: '',
      publishContent: '',
    });

    const closeReleaseForm = () => {
      isEditable.value = false;
      releaseFormRef.value.resetFields();
      ctx.emit('close');
    };

    // 提交与编辑表单信息
    const submitReleaseForm = async () => {
      releaseFormRef.value.validate(async (valid: boolean) => {
        if (valid) {
          btnLoading.value = true;
          if (!isEditable.value) {
            try {
              const { code } = await addApply(form);
              if (code === 0) {
                ElMessage({
                  type: 'success',
                  message: '添加成功',
                });
                ctx.emit('getTableInfo');
              } else {
                ElMessage({
                  type: 'error',
                  message: '添加失败',
                });
              }
              btnLoading.value = false;
              closeReleaseForm();
            } catch (e) {
              console.log(e);
              btnLoading.value = false;
            }
          } else {
            try {
              const { code } = await updateApply(Number(releaseData.value.id), {
                ...form,
              });
              if (code === 0) {
                ElMessage({
                  type: 'success',
                  message: '更新成功',
                });
                ctx.emit('getTableInfo');
              } else {
                ElMessage({
                  type: 'error',
                  message: '编辑失败',
                });
              }
              closeReleaseForm();
              btnLoading.value = false;
            } catch (e) {
              console.log(e);
              btnLoading.value = false;
            }
          }
        }
      });
    };

    const sources = reactive({
      apps: [],
      appLoaded: false,
      services: [],
      serviceLoaded: false,
    });

    const sourceData: any = ref([]);
    // 获取应用列表
    const fetchAppList = async () => {
      const { data } = await getAppsForApply();
      sources.apps = data;
      sources.appLoaded = true;
      return data;
    };

    // 获取服务列表
    const fetchServiceList = async () => {
      const { data } = await getServiceList();
      sources.services = data;
      sources.serviceLoaded = true;
      return data;
    };

    // 类型切换
    const handleTypeChange = async (v: number) => {
      sourceData.value = [];
      form.name = '';
      form.serviceVersion = '';
      try {
        // 获取服务
        if (v === 1) {
          sourceData.value = sources.serviceLoaded ? sources.services : await fetchServiceList();
        }
        // 获取应用
        else {
          sourceData.value = sources.appLoaded ? sources.apps : await fetchAppList();
        }
      } catch (e) {
        console.log(e);
      }

      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      resetServiceAndVersionList();
    };

    const serviceList: any = ref([]);
    const versionList: any = ref([]);

    // 重置版本列表
    const resetVersionList = () => {
      form.serviceVersion = '';
      const currentService = sourceData.value.find((item: any) => item.serviceName === form.name);
      const versions = currentService?.versions?.map((item: any) => {
        const statusStr = ['（发版成功）', '（发版中）', '（发版失败）', '（待依赖发版）'];
        let label;
        switch (item.versionStatus) {
          case 10:
            label = `${item.version}${statusStr[0]}`;
            break;
          case 1:
            label = `${item.version}${statusStr[1]}`;
            break;
          case 2:
            label = `${item.version}${statusStr[2]}`;
            break;
          default:
            label = `${item.version}${statusStr[3]}`;
            break;
        }
        return {
          version: item.version,
          label,
          versionStatus: item.versionStatus,
        };
      });
      versionList.value = versions;
    };

    // 重置服务列表和版本列表
    const resetServiceAndVersionList = () => {
      serviceList.value = [];
      versionList.value = [];
      sourceData.value.forEach((item: any) => {
        serviceList.value.push(item.serviceName);
      });
      resetVersionList();
    };

    // 选择的服务改变
    const handleServiceChange = () => {
      resetVersionList();
    };

    // 重置表单
    const resetForm = () => {
      // eslint-disable-next-line no-restricted-syntax
      const { type, publisherName, publisher, name, serviceVersion, publishContent } = props.releaseForms.serviceInfo;
      const { isEdit } = props.releaseForms;
      form.type = type;
      form.publisher = publisher;
      form.publisherName = publisherName;
      form.name = name;
      form.serviceVersion = serviceVersion;
      form.publishContent = publishContent;
      if (!isEdit) {
        releaseFormRef.value.resetFields();
      }
    };

    onMounted(() => {
      handleTypeChange(form.type);
    });

    watch(
      () => props.visable,
      (v: boolean) => {
        if (v) {
          handleTypeChange(form.type);
          resetForm();
        }
      },
    );

    return {
      labelWidth,
      releaseRules,
      releaseData,
      releaseFormRef,
      isVisable,
      isEditable,
      closeReleaseForm,
      submitReleaseForm,
      btnLoading,
      handleTypeChange,
      serviceList,
      versionList,
      handleServiceChange,
      form,
    };
  },
});
